import Vue from 'vue';
import VueRouter from 'vue-router';
import isEqual from 'lodash/isEqual';

import store from '@/store/index';
import get from 'lodash/get';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/dashboard/Dashboard.vue'),
        meta: {
            help: 'dashboard',
            allowNoActiveCustomer: true,
        },
    },
    {
        path: '/orders',
        name: 'OrderList',
        component: () => import('@/views/orderlist/OrderList.vue'),
        meta: {
            help: 'order-list',
            permissions: {
                [PERMISSIONS.ORDERS]: [PERMISSIONS_VALUES.READ_ONLY, PERMISSIONS_VALUES.READ_WRITE],
            },
        },
    },
    {
        path: '/order',
        name: 'OrderDetailsIndex',
        component: () => import('@/views/shoppingcart/Index.vue'),
        children: [
            {
                props: true,
                path: ':orderId',
                name: 'OrderDetails',
                component: () => import('@/views/shoppingcart/OrderConfirmation.vue'),
                meta: {
                    help: 'order-details',
                    title: 'Order confirmation',
                    permissions: {
                        [PERMISSIONS.ORDERS]: [
                            PERMISSIONS_VALUES.READ_ONLY,
                            PERMISSIONS_VALUES.READ_WRITE,
                        ],
                    },
                },
            },
        ],
    },
    {
        path: '/bulkorder',
        name: 'BulkOrderIndex',
        component: () => import('@/views/shoppingcart/Index.vue'),
        children: [
            {
                props: true,
                path: ':bulkOrderId',
                name: 'BulkOrderDetails',
                component: () => import('@/views/orderlist/components/BulkOrderDetails.vue'),
                meta: {
                    help: 'order-details',
                    permissions: {
                        [PERMISSIONS.ORDERS]: [
                            PERMISSIONS_VALUES.READ_ONLY,
                            PERMISSIONS_VALUES.READ_WRITE,
                        ],
                    },
                },
            },
        ],
    },
    {
        path: '/preorders',
        name: 'PreOrderList',
        props: () => ({
            isPreOrderListMode: true,
        }),
        component: () => import('@/views/reservelist/ReserveList.vue'),
        meta: {
            help: 'preorder-list',
        },
    },
    {
        path: '/reservations',
        name: 'ReserveList',
        component: () => import('@/views/reservelist/ReserveList.vue'),
        meta: {
            help: 'reserve-list',
        },
    },
    {
        props: true,
        path: '/reservation',
        name: 'ReserveDetailsIndex',
        component: () => import('@/views/reservedetails/Index.vue'),
        children: [
            {
                path: ':orderId',
                name: 'ReserveDetails',
                component: () => import('@/views/reservedetails/ReserveDetails.vue'),
                meta: {
                    help: 'reserve-details',
                },
            },
        ],
    },
    {
        props: true,
        path: '/preorder',
        name: 'PreOrderDetailsIndex',
        component: () => import('@/views/reservedetails/Index.vue'),
        children: [
            {
                path: ':orderId',
                name: 'PreOrderDetails',
                props: () => ({
                    isPreOrderMode: true,
                }),
                component: () => import('@/views/reservedetails/ReserveDetails.vue'),
                meta: {
                    help: 'preorder-details',
                },
            },
        ],
    },
    {
        path: '/inventory',
        name: 'InventoryList',
        component: () => import('@/views/inventorylist/InventoryList.vue'),
        meta: {
            help: 'inventory',
        },
    },
    {
        path: '/surgeons',
        name: 'SurgeonList',
        component: () => import('@/views/surgeonlist/SurgeonList.vue'),
        meta: {
            help: 'surgeon-list',
            permissions: {
                [PERMISSIONS.SURGEONS]: [
                    PERMISSIONS_VALUES.READ_ONLY,
                    PERMISSIONS_VALUES.READ_WRITE,
                    PERMISSIONS_VALUES.LIMITED_READ_ONLY,
                ],
            },
        },
    },
    {
        props: true,
        path: '/surgeon',
        name: 'SurgeonDetailsIndex',
        component: () => import('@/views/surgeondetails/Index.vue'),
        children: [
            {
                path: ':doctorId',
                name: 'SurgeonDetails',
                component: () => import('@/views/surgeondetails/SurgeonDetails.vue'),
                meta: {
                    help: 'surgeon-details',
                    permissions: {
                        [PERMISSIONS.SURGEONS]: [
                            PERMISSIONS_VALUES.READ_ONLY,
                            PERMISSIONS_VALUES.READ_WRITE,
                            PERMISSIONS_VALUES.LIMITED_READ_ONLY,
                        ],
                    },
                },
            },
        ],
    },
    {
        path: '/patients',
        name: 'PatientList',
        component: () => import('@/views/patientlist/PatientList.vue'),
        meta: {
            help: 'patient-list',
        },
    },
    {
        path: '/patient/:patientId',
        name: 'PatientDetails',
        props: true,
        meta: {
            help: 'patient-details',
            backPage: 'PatientList',
        },
        component: () => import('@/views/patientdetails/PatientDetails.vue'),
    },
    {
        path: '/newPatient',
        name: 'NewPatient',
        meta: {
            help: 'new-patient',
            backPage: 'PatientList',
            permissions: {
                [PERMISSIONS.CREATE_PATIENT]: PERMISSIONS_VALUES.ENABLED,
            },
        },
        component: () => import('@/views/newpatient/NewPatientPage.vue'),
    },
    {
        props: true,
        path: '/patient/:patientId/preop/:preOpDataSetId?',
        name: 'PreOpData',
        meta: {
            help: 'preop-data',
            backPage: 'PatientDetails',
            initialScrollImmediately: true,
            permissions: {
                [PERMISSIONS.PATIENT_PREOP_DATA]: [
                    PERMISSIONS_VALUES.READ_WRITE,
                    PERMISSIONS_VALUES.READ_ONLY,
                ],
            },
        },
        component: () => import('@/views/CalculatorDevice/PreOpData.vue'),
    },
    {
        path: '/preopreport/:preOpDataSetId?',
        name: 'PreOpReport',
        props: (route) => ({
            debug: route.query.debug === 'true',
        }),
        meta: {
            help: 'preop-report',
            hideNavbar: true,
            permissions: {
                [PERMISSIONS.PATIENT_PREOP_DATA]: [
                    PERMISSIONS_VALUES.READ_WRITE,
                    PERMISSIONS_VALUES.READ_ONLY,
                ],
            },
        },
        component: () => import('@/views/preopreport/PreOpReport.vue'),
    },
    {
        path: '/inventorylookupreport/:debug?',
        name: 'InventoryLookupReport',
        meta: {
            help: 'inventory-lookup-report',
            hideNavbar: true,
        },
        component: () => import('@/views/inventorylookupreport/InventoryLookupReport.vue'),
    },
    {
        path: '/iodreport/:lensOrderId?',
        name: 'IodReport',
        component: () => import('@/views/iodreport/IodReport.vue'),
        props: (route) => ({
            debug: route.query.debug === 'true',
        }),
        beforeEnter: (to, from, next) => {
            if (to.params.lensOrderId == undefined) {
                next({name: 'NotFound'});
            } else {
                next();
            }
        },
        meta: {
            help: 'iod-report',
            hideNavbar: true,
        },
    },
    {
        path: '/bulk',
        name: 'Bulk',
        component: () => import('@/views/bulk/Bulk.vue'),
    },
    {
        path: '/bulk/quickguide',
        name: 'QuickGuide',
        component: () => import('@/views/bulk/quickGuide/QuickGuide.vue'),
        meta: {
            hideNavbar: true,
        },
    },
    {
        path: '/admin',
        name: 'AdminPage',
        component: () => import('@/views/admin/AdminPage.vue'),
        children: [
            {
                path: 'users',
                name: 'UserList',
                component: () => import('@/views/admin/UserList.vue'),
                meta: {
                    permissions: {
                        [PERMISSIONS.USERS]: PERMISSIONS_VALUES.READ_TYPES,
                    },
                    showHeader: true,
                },
            },
            {
                props: true,
                path: 'users/:userId',
                name: 'EditUser',
                component: () => import('@/views/admin/EditUser.vue'),
                meta: {
                    permissions: {
                        [PERMISSIONS.USERS]: PERMISSIONS_VALUES.WRITE_TYPES,
                    },
                    showHeader: false,
                },
            },
        ],
    },
    {
        path: '/shoppingcart',
        name: 'ShoppingCartWrapper',
        component: () => import('@/views/shoppingcart/Index.vue'),
        children: [
            {
                path: '',
                name: 'ShoppingCart',
                component: () => import('@/views/shoppingcart/ShoppingCart.vue'),
                meta: {
                    help: 'shopping-cart',
                    title: 'Shopping cart',
                    permissions: {
                        [PERMISSIONS.ORDERING]: [PERMISSIONS_VALUES.READ_WRITE],
                    },
                },
            },
            {
                path: 'checkout',
                name: 'Checkout',
                component: () => import('@/views/shoppingcart/Checkout.vue'),
                meta: {
                    help: 'checkout',
                    title: 'Checkout',
                },
                children: [
                    {
                        props: true,
                        path: 'shippingdetails',
                        name: 'ShippingDetails',
                        component: () =>
                            import('@/views/shoppingcart/components/AddressDetails.vue'),
                        meta: {
                            help: 'checkout',
                        },
                    },
                    {
                        props: true,
                        path: 'billingdetails',
                        name: 'BillingDetails',
                        component: () =>
                            import('@/views/shoppingcart/components/AddressDetails.vue'),
                        meta: {
                            help: 'checkout',
                        },
                    },
                    {
                        props: true,
                        path: 'paymentdetails',
                        name: 'PaymentDetails',
                        component: () =>
                            import('@/views/shoppingcart/components/PaymentDetails.vue'),
                        meta: {
                            help: 'checkout',
                        },
                    },
                    {
                        props: true,
                        path: 'reviewconfirm',
                        name: 'ReviewConfirm',
                        component: () =>
                            import('@/views/shoppingcart/components/ReviewConfirm.vue'),
                        meta: {
                            help: 'checkout',
                        },
                    },
                ],
            },
            {
                props: true,
                path: 'orderconfirmation',
                name: 'OrderConfirmation',
                component: () => import('@/views/shoppingcart/OrderConfirmation.vue'),
                meta: {
                    help: 'order-confirmation',
                    title: 'Order confirmation',
                    permissions: {
                        [PERMISSIONS.ORDERS]: [
                            PERMISSIONS_VALUES.READ_ONLY,
                            PERMISSIONS_VALUES.READ_WRITE,
                        ],
                    },
                },
            },
            {
                props: true,
                path: 'preorderconfirmation',
                name: 'PreOrderConfirmation',
                component: () => import('@/views/shoppingcart/OrderConfirmation.vue'),
                meta: {
                    help: 'pre-order-confirmation',
                    title: 'Pre Order confirmation',
                    permissions: {
                        [PERMISSIONS.ORDERS]: [
                            PERMISSIONS_VALUES.READ_ONLY,
                            PERMISSIONS_VALUES.READ_WRITE,
                        ],
                    },
                },
            },
        ],
    },
    {
        path: '/loggingout',
        name: 'LoggingOut',
        component: () => import('@/views/LoggingOutPage.vue'),
        meta: {
            help: 'logging-out',
            allowNoActiveCustomer: true,
        },
    },
    {
        path: '/unauthorized',
        name: 'Unauthorized',
        component: () => import('@/views/UnauthorizedPage.vue'),
        meta: {
            allowNoActiveCustomer: true,
        },
    },
    {
        path: '/unavailable',
        name: 'Unavailable',
        component: () => import('@/views/UnavailablePage.vue'),
        meta: {
            allowNoActiveCustomer: true,
        },
    },
    {
        path: '*',
        name: 'NotFound',
        component: () => import('@/views/NotFoundPage.vue'),
        meta: {
            allowNoActiveCustomer: true,
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        // Skip if only query params changed
        if (from && to.name === from.name && isEqual(to.params, from.params)) {
            return;
        }

        let position = {};
        if (to.hash) {
            position = {
                selector: to.hash,
                offset: {x: 0, y: 10},
                behavior: 'smooth',
            };
        } else {
            position = {x: 0, y: 0};
        }

        if (to.meta.initialScrollImmediately) {
            // Delaying the initial scroll can break custom scroll behavior
            // on a route, so allow for resolving the scroll immediately
            return position;
        } else {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(position);
                }, 300);
            });
        }
    },
});

/**
 * This route guard prevents API errors when a user tries to access a page that
 * requires an active customer before an active customer has been set, e.g.
 * when the user is logged out and tries to use a bookmarked URL to a page that
 * requires an active customer.
 */
router.beforeEach((to, from, next) => {
    const requirePermissions = get(to, 'meta.permissions');
    const permissions = store.getters['permissions/permissions'];
    const currentUser = store.getters['user/currentUser'];
    const noapi = store.state.app.noapi;

    if (currentUser && !noapi) {
        if (
            requirePermissions &&
            !checkPermissions(requirePermissions, permissions, currentUser.accessPermissions)
        ) {
            next({name: 'NotFound'});
        } else next();
    } else {
        if (noapi) {
            ['Unavailable', 'LoggingOut'].includes(to.name) ? next() : next({name: 'Unavailable'});
        } else {
            ['Unavailable', 'Unauthorized', 'LoggingOut'].includes(to.name)
                ? next()
                : next({name: 'Unauthorized'});
        }
    }
});

/**
 * Require an active zone and customer/doctor. Can be overriden at the
 * route level by setting `meta.allowNoActiveCustomer` to `true`.
 */
const requireActiveCustomer = (to, from, next) => {
    const hasAccountId = !!to.query['account-id'];
    const activeZone = store.getters['zone/currentZone'];
    const activeCustomerOcosNumberOrId = store.getters['user/activeCustomerOcosNumberOrId'];
    const activeDoctor = store.getters['user/activeDoctorId'];

    if (!to.query['account-id'] && activeCustomerOcosNumberOrId) {
        to.query['account-id'] = activeCustomerOcosNumberOrId;
    }

    if (!to.matched.some((record) => record.meta.allowNoActiveCustomer)) {
        if (!activeZone || !(activeCustomerOcosNumberOrId || activeDoctor)) {
            next({name: 'Dashboard'});
        } else {
            hasAccountId ? next() : next(to);
        }
    } else {
        !hasAccountId && activeCustomerOcosNumberOrId ? next(to) : next();
    }
};

router.beforeEach(requireActiveCustomer);

export default router;
